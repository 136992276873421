import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../../../utils/generic/constants';
import format from 'date-fns/format';

export const requirementEthicsMeetingFrequencyValues = [
  {
    id: 1,
    value: 'Monthly',
  },
  {
    id: 2,
    value: 'Quarterly',
  },
  {
    id: 3,
    value: 'Semi-Annually',
  },
  {
    id: 4,
    value: 'Annually',
  },
  {
    id: 5,
    value: 'Other',
  },
];

export const validityPeriodAccreditationObj = {
  '1-Year': 1,
  '2-Years': 2,
  '3-Years': 3,
  Other: 4,
};

const methodOfAccreditationValues = [
  { id: 1, label: 'Online', value: 1 },
  { id: 2, label: 'Email', value: 2 },
  { id: 3, label: 'Paper form', value: 3 },
  { id: 4, label: 'Fax', value: 4 },
  { id: 5, label: 'Other', value: 5 },
];

const requirementEthicsCtaReviewOutcomeValues = [
  { id: 1, label: 'Approve', value: 1 },
  { id: 2, label: 'Reject', value: 2 },
  { id: 3, label: 'Modifications required', value: 3 },
];

const validityPeriodAccreditationValues = [
  {
    id: 1,
    value: '1-Year',
    label: '1-Year',
  },
  {
    id: 2,
    value: '2-Years',
    label: '2-Years',
  },
  {
    id: 3,
    value: '3-Years',
    label: '3-Years',
  },
  {
    id: 4,
    value: 'Other',
    label: 'Other',
  },
];

export const validate = (values, isValidate, isOthertab) => {
  const errors = {};
  if (
    values?.accreditingAuthority?.trim() &&
    values?.accreditingAuthority?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.accreditingAuthority = 'Please enter only 500 characters';
  }
  if (
    values?.registrationMethodDetails?.trim() &&
    values?.registrationMethodDetails?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.registrationMethodDetails = 'Please enter only 1000 characters';
  }
  if (
    values?.validityPeriodDetails?.trim() &&
    values?.validityPeriodDetails?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.validityPeriodDetails = 'Please enter only 1000 characters';
  }
  if (
    values?.meetingFrequencyDetails?.trim() &&
    values?.meetingFrequencyDetails?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.meetingFrequencyDetails = 'Please enter only 500 characters';
  }

  if (
    values?.nationalEthicsCommittee?.trim() &&
    values?.nationalEthicsCommittee?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.nationalEthicsCommittee = 'Please enter only 400 characters';
  }
  if (
    values?.roleOrFunctionNationalEthicsCommittee?.trim() &&
    values?.roleOrFunctionNationalEthicsCommittee?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.roleOrFunctionNationalEthicsCommittee = 'Please enter only 1000 characters';
  }
  if (
    values?.regionalEthicsCommittee?.trim() &&
    values?.regionalEthicsCommittee?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.regionalEthicsCommittee = 'Please enter only 400 characters';
  }
  if (
    values?.roleOrFunctionRegionalEthicsCommittee?.trim() &&
    values?.roleOrFunctionRegionalEthicsCommittee?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.roleOrFunctionRegionalEthicsCommittee = 'Please enter only 1000 characters';
  }
  if (
    values?.ecForCtSubmission?.trim() &&
    values?.ecForCtSubmission?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.ecForCtSubmission = 'Please enter only 1000 characters';
  }
  if (
    values?.documentSubmissionTypeOtherEthics?.trim() &&
    values?.documentSubmissionTypeOtherEthics?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.documentSubmissionTypeOtherEthics = 'Please enter only 400 characters';
  }
  if (
    values?.timelinesForReviewOtherEthics?.trim() &&
    values?.timelinesForReviewOtherEthics?.trim().length > TEXT_LIMIT[500]
  ) {
    errors.timelinesForReviewOtherEthics = 'Please enter only 500 characters';
  }
  if (
    values?.furtherDetailsOnAutomaticAcceptanceEthics?.trim() &&
    values?.furtherDetailsOnAutomaticAcceptanceEthics?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.furtherDetailsOnAutomaticAcceptanceEthics = 'Please enter only 1000 characters';
  }
  if (
    values?.orderOfSubmissionOtherEthics?.trim() &&
    values?.orderOfSubmissionOtherEthics?.trim().length > TEXT_LIMIT[400]
  ) {
    errors.orderOfSubmissionOtherEthics = 'Please enter only 400 characters';
  }
  if (
    values?.feesForSubmissionEthics?.trim() &&
    values?.feesForSubmissionEthics?.trim().length > TEXT_LIMIT[1000]
  ) {
    errors.feesForSubmissionEthics = 'Please enter only 1000 characters';
  }

  if (isValidate) {
    if (values?.isEthicsRequirementConduct === null) {
      errors.isEthicsRequirementConduct = 'Please select conduct of clinical trial';
    }
    if (
      values?.timelinesForReviewEthics === 'Other' ||
      values?.timelinesForReviewEthics?.value === 'Other'
    ) {
      if (!values?.timelinesForReviewOtherEthics?.trim()) {
        errors.timelinesForReviewOtherEthics = 'Please enter other timeline ethics review';
      }
    }
  }
  return errors;
};

const columnDefs = [
  {
    headerName: 'DOCUMENT ID',
    field: 'id',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];

export const layoutSchema = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'isEthicsRequirementConduct',
      size: 4,
    },
    {
      layout: 'isFavourableEthicsCondition',
      size: 4,
    },
    {
      layout: 'nationalEthicsCommittee',
      size: 4,
    },
    {
      layout: 'roleOrFunctionNationalEthicsCommittee',
      size: 6,
    },
    {
      layout: 'regionalEthicsCommittee',
      size: 6,
    },
    {
      layout: 'roleOrFunctionRegionalEthicsCommittee',
      size: 6,
    },
    {
      layout: 'isAccreditationRequired',
      size: 6,
      className: 'timeline',
    },
    {
      layout: 'accreditationProcess',
      size: 6,
    },
    {
      layout: 'accreditingAuthority',
      size: 6,
    },
    {
      layout: 'requirementEthicsMethodOfAccreditation',
      size: 4,
    },
    {
      layout: 'registrationMethodDetails',
      size: 8,
    },
    {
      layout: 'validityPeriodAccreditation',
      size: 4,
      className: 'timeline',
    },
    {
      layout: 'validityPeriodDetails',
      size: 8,
    },
    {
      layout: 'linkingEthicsCommitteeDoc',
      size: 6,
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'linkingEthicsCommitteeDocTable',
      size: 12,
      className: 'linkingEthicsCommitteeDocTable',
    },
    {
      layout: 'fullNameofRequirement',
      size: 4,
    },
    {
      layout: 'languages',
      size: 4,
    },
    {
      layout: 'currentVersion',
      size: 4,
    },
    {
      layout: 'ecForCtSubmission',
      size: 8,
    },
    {
      layout: 'keyDocumentsForEthicsSubmission',
      size: 4,
    },
  ],
};

export const formSchema = {
  isEthicsRequirementConduct: {
    label: 'Are there any Ethics Requirements for the conduct of Clinical Trial in this country?',
    name: 'isEthicsRequirementConduct',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: true,
    },
  },
  isFavourableEthicsCondition: {
    label: 'Condition of favourable ethics approval required to conduct clinical trial?',
    name: 'isFavourableEthicsCondition',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },

  nationalEthicsCommittee: {
    label: 'National Ethics Committee responsible for clinical trial authorization',
    name: 'nationalEthicsCommittee',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  roleOrFunctionNationalEthicsCommittee: {
    label: 'Role or function of national ethics committee',
    name: 'roleOrFunctionNationalEthicsCommittee',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  regionalEthicsCommittee: {
    label: 'Regional Ethics Committee responsible for clinical trial authorization',
    name: 'regionalEthicsCommittee',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  roleOrFunctionRegionalEthicsCommittee: {
    label: 'Role or function of regional ethics committee',
    name: 'roleOrFunctionRegionalEthicsCommittee',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  isAccreditationRequired: {
    name: 'isAccreditationRequired',
    label: 'Is ethics committee accreditation required?',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: 'Mandatory',
        label: 'Mandatory',
      },
      {
        id: 2,
        value: 'Voluntary',
        label: 'Voluntary',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  accreditationProcess: {
    label: 'What is the process for EC accreditation?',
    name: 'accreditationProcess',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  accreditingAuthority: {
    label: 'What is the authority that accredits the ethics committee?',
    name: 'accreditingAuthority',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  requirementEthicsMethodOfAccreditation: {
    name: 'requirementEthicsMethodOfAccreditation',
    type: fieldTypes.select,
    label: 'Method of accreditation',
    options: methodOfAccreditationValues,
    props: {
      height: 'ht-lg',
      isMandatory: false,
      isSearchable: true,
      isClearable: true,
      isMulti: true,
    },
  },
  registrationMethodDetails: {
    label: 'If other, further details of the method of registration',
    name: 'registrationMethodDetails',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  validityPeriodAccreditation: {
    name: 'validityPeriodAccreditation',
    label: 'What is the validity period of the accreditation?',
    type: fieldTypes.radio,
    options: validityPeriodAccreditationValues,
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  validityPeriodDetails: {
    label: 'If other, further details of the validity period of the accreditation',
    name: 'validityPeriodDetails',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
      isMandatory: false,
    },
  },
  linkingEthicsCommitteeDoc: {
    name: 'linkingEthicsCommitteeDoc',
    type: fieldTypes.select_async,
    label: 'Linking to Ethics Guideline or Requirement',
    props: {
      isSearchable: true,
      isClearable: true,
      isMulti: false,
      showSelectedData: false,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  linkingEthicsCommitteeDocTable: {
    name: 'linkingEthicsCommitteeDocTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs,
      rowHeight: 35,
    },
  },
  fullNameofRequirement: {
    label: 'Full (formal) name of the requirement',
    name: 'fullNameofRequirement',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  languages: {
    name: 'languages',
    type: fieldTypes.textArea,
    label: 'Language(s) in which the requirement is written',
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  currentVersion: {
    label: 'Current version or revision number of the requirement',
    name: 'currentVersion',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      disabled: true,
      placeholder: '',
    },
  },
  empty: {},
  ecForCtSubmission: {
    label: 'To which EC do clinical trial submission need to be sent?',
    name: 'ecForCtSubmission',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  keyDocumentsForEthicsSubmission: {
    name: 'keyDocumentsForEthicsSubmission',
    type: fieldTypes.select_async,
    label: 'Key documents for ethics submission',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
};

export const layoutSchema1 = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'ethicsDocumentSubmissionType',
      size: 4,
    },
    {
      layout: 'documentSubmissionTypeOtherEthics',
      size: 8,
    },
    {
      layout: 'requirementEthicsMeetingFrequency',
      size: 6,
    },
    {
      layout: 'meetingFrequencyDetails',
      size: 6,
    },
    {
      layout: 'requirementEthicsLanguage',
      size: 4,
    },
    {
      layout: 'timelinesForReviewEthics',
      size: 5,
      className: 'timeline',
    },
    {
      layout: 'timelinesForReviewOtherEthics',
      size: 8,
    },
    {
      layout: 'requirementEthicsCtaReviewOutcomes',
      size: 4,
    },
    {
      layout: 'automaticAcceptanceEthics',
      size: 5,
      className: 'timeline',
    },
    {
      layout: 'furtherDetailsOnAutomaticAcceptanceEthics',
      size: 5,
    },
    {
      layout: 'orderOfSubmissionEthics',
      size: 6,
      className: 'timeline',
    },
    {
      layout: 'orderOfSubmissionOtherEthics',
      size: 5,
      //className: 'timeline',
    },
    {
      layout: 'requirementExemptionsToEthicsReview',
      size: 4,
      //className: 'timeline',
    },
    {
      layout: 'exemptionsToReviewDetails',
      size: 8,
      //className: 'timeline',
    },
    {
      layout: 'feesForSubmissionEthics',
      size: 6,
      //className: 'timeline',
    },
  ],
};
export const submissionOption = [
  {
    id: 1,
    value: 'Concurrent',
    label: 'Concurrent',
  },
  {
    id: 2,
    value: 'Parallel',
    label: 'Parallel',
  },
  {
    id: 3,
    value: 'Sequential-first',
    label: 'Sequential - first',
  },
  {
    id: 4,
    value: 'Sequential-second',
    label: 'Sequential - second',
  },
  {
    id: 5,
    value: 'Other',
    label: 'Other',
  },
];

export const formSchema1 = {
  ethicsDocumentSubmissionType: {
    label: 'Document submission type',
    name: 'ethicsDocumentSubmissionType',
    type: fieldTypes.select_async,
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  documentSubmissionTypeOtherEthics: {
    name: 'documentSubmissionTypeOtherEthics',
    type: fieldTypes.textArea,
    label: 'If Other - Document submission type',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  requirementEthicsMeetingFrequency: {
    name: 'requirementEthicsMeetingFrequency',
    label: 'Ethics Committee Meeting Frequency',
    type: fieldTypes.checkbox,
    options: requirementEthicsMeetingFrequencyValues,
    props: {
      // isMandatory: true,
    },
  },
  meetingFrequencyDetails: {
    label: 'Further details on ethics committee meeting frequency',
    name: 'meetingFrequencyDetails',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 2.4,
      rowsMax: 5,
    },
  },
  requirementEthicsLanguage: {
    name: 'requirementEthicsLanguage',
    type: fieldTypes.select_tag,
    label: 'Language(s) in which data can be reported',
    props: {
      isMandatory: false,
    },
  },
  timelinesForReviewEthics: {
    name: 'timelinesForReviewEthics',
    label: 'Timelines for ethics review',
    type: fieldTypes.radio,
    options: [
      {
        id: 1,
        value: '30 Days',
        label: '30 Days',
      },
      {
        id: 2,
        value: '45 Days',
        label: '45 Days',
      },
      {
        id: 3,
        value: '60 Days',
        label: '60 Days',
      },
      {
        id: 4,
        value: '90 Days',
        label: '90 Days',
      },
      {
        id: 5,
        value: 'Other',
        label: 'Other',
      },
    ],
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },
  timelinesForReviewOtherEthics: {
    name: 'timelinesForReviewOtherEthics',
    type: fieldTypes.textArea,
    label: 'If Other - Timelines for ethics review',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  requirementEthicsCtaReviewOutcomes: {
    name: 'requirementEthicsCtaReviewOutcomes',
    type: fieldTypes.select,
    label: 'Clinical trial application review outcomes',
    options: requirementEthicsCtaReviewOutcomeValues,
    props: {
      height: 'ht-lg',
      isMandatory: false,
      isSearchable: true,
      isClearable: true,
      isMulti: true,
    },
  },
  automaticAcceptanceEthics: {
    label: 'Automatic acceptance after specified time of review',
    name: 'automaticAcceptanceEthics',
    type: fieldTypes.custom_radio,
    options: [
      { id: 1, display: 'Yes' },
      { id: 0, display: 'No' },
    ],
    props: {
      isMandatory: false,
    },
  },
  furtherDetailsOnAutomaticAcceptanceEthics: {
    name: 'furtherDetailsOnAutomaticAcceptanceEthics',
    type: fieldTypes.textArea,
    label: 'Further details on automatic acceptance ',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  orderOfSubmissionEthics: {
    name: 'orderOfSubmissionEthics',
    label: 'Order of submission',
    type: fieldTypes.radio,
    options: submissionOption,
    props: {
      type: 'group',
      className: 'timelineRadioClass',
    },
  },

  orderOfSubmissionOtherEthics: {
    name: 'orderOfSubmissionOtherEthics',
    type: fieldTypes.textArea,
    label: 'If Other - Order of submission',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  requirementExemptionsToEthicsReview: {
    label: 'Exemptions to Ethics Committee review',
    name: 'requirementExemptionsToEthicsReview',
    type: fieldTypes.select_async,
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },

  exemptionsToReviewDetails: {
    name: 'exemptionsToReviewDetails',
    type: fieldTypes.textArea,
    label: 'Further details on exemptions to Ethics Committee review',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
  feesForSubmissionEthics: {
    name: 'feesForSubmissionEthics',
    type: fieldTypes.textArea,
    label: 'Fees for ethics submission',
    props: {
      rowsMin: 2.4,
      placeholder: '',
      rowsMax: 5,
    },
  },
};

export const timelinesOptObj = {
  '30 Days': 1,
  '45 Days': 2,
  '60 Days': 3,
  '90 Days': 4,
  Other: 5,
};

export const manVolObj = {
  Mandatory: 1,
  Voluntary: 2,
};

const columnDefs1 = [
  {
    headerName: 'DOCUMENT ID',
    field: 'value',
    width: 250,
    sortable: true,
    cellRenderer: 'DefaultCellRender',
  },
  {
    headerName: 'DOCUMENT TITLE',
    field: 'documentTitle',
    width: 400,
  },
  {
    headerName: 'Regulatory Date',
    field: 'regulatoryDate',
    width: 350,
    sortable: true,
    cellRenderer: ({ value }) => {
      return value ? format(new Date(value), 'dd-MMM-yyyy') : 'NA';
    },
  },
];
export const acceptanceTimeline = {
  Concurrent: 1,
  Parallel: 2,
  'Sequential-first': 3,
  'Sequential-second': 4,
  Other: 5,
};

export const layoutSchema2 = {
  layout: 'row',
  spacing: 1,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'sourceDocs',
      size: 4,
      className: 'sourceDocs',
    },
    {
      layout: 'empty',
      size: 8,
    },
    {
      layout: 'sourceDocsTable',
      size: 12,
      className: 'sourceDocsTable',
    },
    {
      layout: 'internalCommentsEthics',
      size: 12,
    },
  ],
};

export const formSchema2 = {
  sourceDocs: {
    name: 'sourceDocs',
    type: fieldTypes.select_async,
    label: 'ADDITIONNAL LINKING TO Other Source Documents',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      isMulti: true,
      showSelectedData: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
    },
  },
  sourceDocsTable: {
    name: 'sourceDocsTable',
    type: fieldTypes.dndDataGrid,
    props: {
      columnDefs: columnDefs1,
      rowHeight: 35,
    },
  },
  internalCommentsEthics: {
    name: 'internalCommentsEthics',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 10,
      rowsMax: 5,
      cols: 150,
    },
  },
  empty: {},
};
